<template>
  <div>
    <h1>Quality Assurance</h1>
    <p>On this page you can set if a shipments quality should be checked.</p>

    <add-to-quality-assurance-modal
      v-model="supplier"
      :name="addDataModalName"
      :get-item-from-table="getItemFromTable"
      :mutate="mutateQualityAssurance"
      :refresh="getOriginalFabricesItems"
      :callback="add_data_callback"
    />

    <quality-assurance-approve-modal
      :name="approveModalName"
      :row="approveModalRow"
      :mutate-quality-assurance="mutateQualityAssurance"
      :parse-approved="parseApproved"
      :final-callback="finalCallback"
    />

    <i-table
      ref="ITable"
      :fetch-items="fetchItemcompanys"
      :fields="fields"
      :actions="actions"
      app-name="quality-assurance"
      :allowed-selectors="allowed_selectors"
      :add_data_modal="addDataModalName"
      :errors="errors"
      @update:selectBy="(v) => (select_by = v)"
    >
      <template #help-tab>
        <QualityAssuranceHelp />
      </template>
      <template #cell(approved)="row">
        <b-container>
          <b-row class="d-flex align-items-center" style="padding-bottom: 0; padding-top: 0">
            <b-col v-b-tooltip.hover title="Look at the help tab for more information">
              <b-form-checkbox v-model="row.item.approved" switch disabled />
            </b-col>
            <b-col>
              <button id="edit_approved_button" class="btn btn-sm btn-primary" @click="showItemApproveModal(row)">
                <b-icon icon="pencil" aria-hidden="true" />
              </button>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <template #row-details="row">
        <div v-if="row.item.loading">
          <div class="d-flex align-content-center justify-content-center">
            <b-spinner label="Loading..." variant="primary" />
          </div>
        </div>
        <div v-else-if="row.item.qualityAssurances && row.item.qualityAssurances.length > 0">
          <quality-assurance-batch-table
            :item="row.item"
            :callback="mutateQualityAssurance"
            :parse-approved="parseApproved"
          />
        </div>
        <div v-else class="text-center">
          <p>No batches found.</p>
        </div>
      </template>
    </i-table>
  </div>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'

import {
  FetchQualityAssuranceItemcompanysDocument,
  FetchQualityAssurancesDocument,
  MutateQualityAssurancesDocument,
} from '@/graphql/generated'
import AddToQualityAssuranceModal from '@/views/incoming_goods/AddToQualityAssuranceModal.vue'
import QualityAssuranceApproveModal from '@/views/incoming_goods/QualityAssuranceApproveModal.vue'
import QualityAssuranceBatchTable from '@/views/incoming_goods/QualityAssuranceBatchTable.vue'
import QualityAssuranceHelp from '@/views/incoming_goods/QualityAssuranceHelp.vue'

export default {
  name: 'QualityAssurance',
  components: {
    QualityAssuranceHelp,
    AddToQualityAssuranceModal,
    QualityAssuranceApproveModal,
    QualityAssuranceBatchTable,
  },
  mixins: [api_mixin],
  data() {
    return {
      addDataModalName: 'add_data_modal',
      errors: [],
      fields: [
        { key: 'ItemCode', label: 'Item Code', selected: true, sortable: true, editable: false },
        { key: 'Description', label: 'Description', selected: true, sortable: true, editable: false },
        { key: 'approved', label: 'Approved', selected: true, sortable: true, editable: true },
      ],
      actions: [
        {
          key: 'toggle_details',
          title: 'Show batches',
          icon: 'file-earmark-diff',
          variant: 'b-blue',
          disallow: false,
          execute: async (item) => {
            this.$refs.ITable.toggleDetails(item)
            if (item._showDetails) {
              item.loading = true
              this.getOriginalFabricesItems(item)
            }
          },
        },
        {
          key: 'add_data',
          execute_global: this.showAddDataModal,
        },
      ],
      select_by: null,
      approveModalRow: null,
      approveModalName: 'approveModal',
    }
  },
  computed: {
    allowed_selectors() {
      return this.$store.state.user.is_employee ? [['trusted_supplier']] : []
    },
    supplier: {
      get() {
        return this.select_by ? this.select_by.trusted_supplier.supplier : null
      },
      set(value) {
        this.select_by.trusted_supplier.supplier = value
      },
    },
    trusted() {
      return this.select_by ? this.select_by.trusted_supplier.trusted : null
    },
  },
  methods: {
    add_data_callback(data) {
      this.$refs.ITable.addItem(data)
    },
    parseApproved(qualityAssurance) {
      return (
        !qualityAssurance.quality_at_door &&
        (qualityAssurance.trusted_supplier || qualityAssurance.quality_before || qualityAssurance.contractors)
      )
    },
    parseQualityAssurance(qualityAssurance) {
      return {
        ItemCode: qualityAssurance.item.ItemCode,
        Description: qualityAssurance.item.Description,
        originalFabric: qualityAssurance.original_fabric,
        approved: this.parseApproved(qualityAssurance),
        trusted_supplier: qualityAssurance.trusted_supplier,
        quality_at_door: qualityAssurance.quality_at_door,
        quality_before: qualityAssurance.quality_before,
        contractors: qualityAssurance.contractors,
        comment: qualityAssurance.comment,
      }
    },
    parseItemcompany(itemcompany) {
      return {
        ItemCode: itemcompany.item_id,
        Description: itemcompany.item__Description,
        approved: itemcompany.item_approved,
        loading: false,
        qualityAssurances: [],
      }
    },
    mutateQualityAssurance(data, company = this.supplier?.cmp_wwn) {
      const mutationInput = [
        {
          ItemCode: data.ItemCode,
          company: company,
          original_fabric: data.originalFabric,
          trusted_supplier: data.trusted_supplier,
          quality_at_door: data.quality_at_door,
          quality_before: data.quality_before,
          contractors: data.contractors,
          comment: data.comment,
        },
      ]
      return this.api_call_feedback(MutateQualityAssurancesDocument, { input: mutationInput }).then(
        (qualityAssuranceResponse) => {
          if (qualityAssuranceResponse.data.errors) {
            this.errors.concat(qualityAssuranceResponse.data.errors)
            return []
          } else {
            this.errors = []
            return qualityAssuranceResponse.data.data.MutateQualityAssurance.QualityAssuranceFields[0]
          }
        }
      )
    },
    fetchItemcompanys(filters) {
      const company = filters?.trusted_supplier?.supplier?.cmp_wwn
      if (company) {
        return this.api_call(FetchQualityAssuranceItemcompanysDocument, {
          company: company,
        }).then((itemcompanyResponse) => {
          if (itemcompanyResponse.data.errors) {
            this.errors.concat(itemcompanyResponse.errors)
            return []
          }
          return itemcompanyResponse.data.data.quality_assurance_company_items.map((itemcompany) =>
            this.parseItemcompany(JSON.parse(itemcompany))
          )
        })
      } else {
        return null
      }
    },
    getOriginalFabricesItems(item) {
      const ItemCode = item?.ItemCode
      if (!ItemCode) {
        return []
      }
      this.fetchQualityAssurance(item).then((qualityAssurances) => {
        item.qualityAssurances = qualityAssurances.map((qualityAssurance) => {
          qualityAssurance.item = item
          return qualityAssurance
        })
        item.approved = qualityAssurances.every((qualityAssurance) => qualityAssurance.approved)
        item.loading = false
      })
    },
    fetchQualityAssurance(item) {
      return this.api_call(FetchQualityAssurancesDocument, {
        item: item.ItemCode,
      }).then((qualityAssurancesResponse) => {
        if (qualityAssurancesResponse.errors) {
          this.errors.concat(qualityAssurancesResponse.errors)
          return []
        }
        return qualityAssurancesResponse.data.data.quality_assurance.edges.map((qualityAssurance) =>
          this.parseQualityAssurance(qualityAssurance.node)
        )
      })
    },
    // Add data modal methods
    async showAddDataModal() {
      this.$bvModal.show(this.addDataModalName)
    },
    getItemFromTable(ItemCode) {
      return this.$refs.ITable?.items?.find((item) => item.ItemCode === ItemCode)
    },
    // Item approve modal methods
    showItemApproveModal(row) {
      this.approveModalRow = row
      this.$bvModal.show(this.approveModalName)
    },
    finalCallback() {
      this.approveModalRow = null
    },
  },
}
</script>
