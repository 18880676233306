<template>
  <div>
    <generic-selector
      v-model="user"
      :data="users"
      :disabled="disabled"
      :serializer="serialize_user"
      :reset-confirmation-prompt="resetConfirmationPrompt"
      invalid-feedback-message="This user does not exist!"
      placeholder="Enter a user..."
    ></generic-selector>
  </div>
</template>

<script lang="ts">
export default {
  name: 'UserSelector',
  props: {
    value: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetConfirmationPrompt: String,
  },
  data() {
    return {
      users: [],
    }
  },
  computed: {
    user: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  mounted() {
    this.users = this.$store.state.users
  },
  methods: {
    serialize_user(user) {
      return user ? user.first_name + ' ' + user.last_name : ''
    },
  },
}
</script>

<style scoped></style>
