<template>
  <div v-if="test_flag">
    <h1>{{ reference }}</h1>
    <p>On this page you can scan new incoming goods.</p>

    <incoming-goods-check-input
      v-if="mutable"
      :table-mode="tableMode"
      :enter-edit-function="enterEditMode"
      @update:barcode="(item) => checkFromInput(item)"
    />

    <incoming-goods-results-modal
      v-if="mutable"
      :name="results_modal_name"
      :callback="send_to_exact"
      :items="incorrect_goods"
    />

    <template v-if="$store.state.user.is_employee">
      <i-table
        ref="ITable"
        :mutate-items="mutateIncomingGoods"
        :fetch-items="fetchIncomingGoods"
        :fields="fields"
        :actions="actions"
        :app-name="app_name"
        :errors="errors"
        :settings="{ sticky_header: { enabled: true, height: 800 } }"
        @update:selectBy="(v) => (select_by = v)"
        @update:tableMode="(tm) => (tableMode = tm)"
      >
        <template #row-details="row">
          <span class="text-danger">
            {{ row.item.error }}
          </span>
        </template>
      </i-table>
    </template>
  </div>
</template>

<script lang="ts">
import { TABLE_MODE } from 'innicore/components/table/TableModeMixin'
import useTableDefaultFields, { DefaultFieldGroups } from 'innicore/components/table/useTableDefaultFields'
import alerts, { VARIANT } from 'innicore/mixins/alerts'
import api_mixin from 'innicore/mixins/api_mixin.js'
import utils from 'innicore/mixins/utils.js'

import errorSound from '@/assets/audio/error.mp3'
import successSound from '@/assets/audio/success.mp3'
import warningSound from '@/assets/audio/warning.mp3'
import {
  FetchIncomingGoodsDocument,
  FetchShipmentDocument,
  IncomingGoodStatus,
  MutateShipmentSendToExactDocument,
} from '@/graphql/generated'
import IncomingGoodsAPIMixin from '@/views/incoming_goods/IncomingGoodsAPIMixin'
import IncomingGoodsCheckInput from '@/views/incoming_goods/IncomingGoodsCheckInput.vue'
import IncomingGoodsPrintMixin from '@/views/incoming_goods/IncomingGoodsPrintMixin'
import IncomingGoodsResultsModal from '@/views/incoming_goods/IncomingGoodsResultsModal.vue'
import { matchScannedBarcode } from '@/views/incoming_goods/matchScannedBarcode.ts'
import { IncomingGood } from '@/views/incoming_goods/types'

export default {
  name: 'IncomingGoods',
  components: {
    IncomingGoodsCheckInput,
    IncomingGoodsResultsModal,
  },
  mixins: [api_mixin, utils, alerts, IncomingGoodsAPIMixin, IncomingGoodsPrintMixin],
  data() {
    return {
      test_flag: this.$store.state.user.is_tester,
      results_modal_name: 'incoming_goods_results_modal',
    }
  },
  computed: {
    actions() {
      return [
        {
          key: 'delete',
          disallowForRow: (row) => !row.item.overdelivery,
        },
        {
          key: 'edit',
          disallow: true,
        },
        {
          key: 'print_label',
          title: 'Print label',
          icon: 'printer',
          variant: 'primary',
          execute: (item) => this.makeLabel([item]),
          execute_bulk: (items) => this.makeLabel(items),
        },
        {
          key: 'send_to_exact',
          title: 'Send to Exact',
          variant: 'secondary',
          icon: 'upload',
          execute_global: this.show_results_modal,
          modes: [TABLE_MODE.READ],
          disallow: !this.mutable,
        },
        {
          key: 'report_box',
          title: 'Report',
          icon: 'exclamation-triangle-fill',
          variant: 'warning',
          execute: (item) => this.reportItem(item),
          disallowForRow: (row) => row.item.damaged,
          modes: [TABLE_MODE.WRITE],
          disallow: !this.mutable,
        },
      ]
    },
    reference() {
      return this.shipment?.reference
    },
    mutable() {
      if (!this.shipment) return false
      if (!this.reference) return false
      if (this.shipment.status == IncomingGoodStatus.DRAFT) return true
      return this.shipment.status == IncomingGoodStatus.REJECTED
    },
    incorrect_goods() {
      return this.$refs.ITable.items.filter((ig) => ig.error || !ig.checked)
    },
  },
  async mounted() {
    this.fields = useTableDefaultFields(
      [DefaultFieldGroups.ItemAttributes, DefaultFieldGroups.SystemInfo],
      this.extraFields
    )

    if (!this.$route.query.shipment) return

    const fetch_shipment_response = await this.api_call(FetchShipmentDocument, { id: this.$route.query.shipment })
    this.shipment = this.parseShipment(fetch_shipment_response.data.data.incoming_goods_shipment.edges[0].node)
    this.supplier = this.shipment.company
  },
  methods: {
    async fetchIncomingGoods() {
      if (!this.shipment?.id) return []
      return this.api_call(FetchIncomingGoodsDocument, {
        shipment: this.shipment.id,
      }).then((incoming_goods_response) => {
        return incoming_goods_response.data.data.incoming_goods.edges.map((obj) => {
          obj.node.arrival_timestamp = new Date(obj.node.arrival_timestamp)
          obj.node.item = this.findBaseItemItemCode(obj.node.item.ItemCode)
          return new IncomingGood(obj.node)
        })
      })
    },
    checkFromInput(incoming_good: IncomingGood) {
      const item_to_check = matchScannedBarcode(this.$refs.ITable.items, incoming_good)
      if (!item_to_check) {
        this.playErrorSound()
        // If the scanned item is not in this packing slip then this is an error
        incoming_good.overdelivery = true
        this.$refs.ITable.items.unshift(incoming_good)
        return
      }
      this.playSuccessSound()
      if (item_to_check.quantity_net == incoming_good.quantity_net) {
        //If the found quantity is
        item_to_check.checked = true
        this.$refs.ITable.items.splice(this.$refs.ITable.items.indexOf(item_to_check), 1)
        this.$refs.ITable.items.unshift(item_to_check)
        return
      }
      item_to_check.quantity_net -= incoming_good.quantity_net
      item_to_check.quantity_gross -= incoming_good.quantity_gross

      incoming_good.checked = true
      this.$refs.ITable.items.unshift(incoming_good)
    },
    editcell(key) {
      return `editcell(${key})`
    },
    async send_to_exact() {
      const feedback_options = { emails: [this.support_address] }
      const response = await this.api_call_feedback(
        MutateShipmentSendToExactDocument,
        { id: this.shipment?.id },
        feedback_options
      )
      if (response.data.data.MutateShipmentSendToExact.Shipment) {
        // Only change the of the current shipment if it was successful
        this.shipment.status = response.data.data.MutateShipmentSendToExact.Shipment.status
      }
    },
    show_results_modal() {
      this.$bvModal.show(this.results_modal_name)
    },
    async enterEditMode() {
      await this.$refs.ITable.enterEditMode([])
    },
    getShipmentId() {
      return this.shipment?.id
    },
    reportItem(item) {
      item.damaged = true
    },
    playSuccessSound() {
      new Audio(successSound).play()
    },
    playErrorSound() {
      new Audio(errorSound).play()
    },
    playWarningSound() {
      new Audio(warningSound).play()
    },
  },
}
</script>
