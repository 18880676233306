<template>
  <div>
    <generic-selector
      v-model="app"
      :data="apps"
      :disabled="disabled"
      :serializer="serialize_app"
      :reset-confirmation-prompt="resetConfirmationPrompt"
      invalid-feedback-message="This app does not exist!"
      placeholder="Enter an app name..."
    ></generic-selector>
  </div>
</template>

<script lang="ts">
export default {
  name: 'AppSelector',
  props: {
    value: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetConfirmationPrompt: String,
  },
  data() {
    return {
      apps: [],
    }
  },
  computed: {
    app: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  mounted() {
    // if necessary, this can be made dynamic (based on api)
    this.apps = [{ app: 'forecast', label: 'Forecast' }]
  },
  methods: {
    serialize_app(app) {
      return app ? app.label : ''
    },
  },
}
</script>

<style scoped></style>
