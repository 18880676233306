<script lang="ts">
import Forecast from 'innicore/views/forecast/Forecast.vue'

import { FetchForecastDocument, MutateRollingForecastsDocument } from '@/graphql/generated'

export default {
  extends: Forecast,
  methods: {
    getQuery() {
      return FetchForecastDocument
    },
    getMutation() {
      return MutateRollingForecastsDocument
    },
  },
}
</script>
