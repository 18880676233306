<template>
  <b-modal :id="name" centered :size="size">
    <b-container fluid>
      <div v-if="checkedItems.length">
        <div class="p-2">There are some errors left. Check the failing rows and try again.</div>
        <b-table striped :items="checkedItems" />
      </div>
      <div v-else style="text-align: center">
        <h4 class="p-2">All checks passed!</h4>
        <b-icon icon="check-circle" variant="success" font-scale="5" />
      </div>
    </b-container>
    <template #modal-footer="{ cancel, ok }">
      <b-button variant="secondary" @click="cancel()">Cancel</b-button>

      <b-button v-if="!checkedItems.length" variant="primary" @click="() => handleOK(ok)"> OK</b-button>
    </template>
  </b-modal>
</template>
<script lang="ts">
export default {
  props: {
    callback: {
      type: Function,
      default: () => {
        return
      },
    },
    name: { type: String, default: '' },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  computed: {
    checkedItems() {
      const checked_items = []
      const order_set = [...new Set(this.items.map((item) => item.order_nr))]
      for (const purchase_order of order_set) {
        const purchase_order_lines = this.items.filter((incoming_good) => incoming_good.order_nr == purchase_order)

        for (const purchase_line of purchase_order_lines) {
          // when the items get here it is either overdelivered or not checked.
          // They cannot overlap so we can assume that if it is not overdelivered it is not checked
          const error = purchase_line.overdelivery ? 'Extra' : 'Missing'
          const item = {
            ItemCode: purchase_line.ItemCode,
            Description: purchase_line.Description,
            BatchCode: purchase_line.batch_code,
            Quantity: purchase_line.quantity_net,
            Error: error,
            _rowVariant: 'danger',
          }
          checked_items.push(item)
        }
      }
      return checked_items
    },
    size() {
      return this.checkedItems.length ? 'lg' : 's'
    },
  },
  methods: {
    async handleOK(okFunction: () => void) {
      await this.callback()
      okFunction()
    },
  },
}
</script>
