import Vue from 'vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

import { createApp } from 'innicore/app'
import 'innicore/custom.scss'
import { store } from 'innicore/store'
import MonthlyForecast from 'innicore/views/forecast/MonthlyForecast.vue'

import InfoTool from '@/components/InfoTool.vue'
import SupportEmailAddress from '@/components/SupportEmailAddress.vue'
import ShipmentSelector from '@/components/inputs/ShipmentSelector.vue'
import ShipmentStatusSelector from '@/components/inputs/ShipmentStatusSelector.vue'
import SupplierSelector from '@/components/inputs/SupplierSelector.vue'
import TrustedSupplierSelector from '@/components/inputs/TrustedSupplierSelector.vue'
import AppSelector from '@/components/selectors/AppSelector.vue'
import CollectionItemSelector from '@/components/selectors/CollectionItemSelector.vue'
import DataTypeSelector from '@/components/selectors/DataTypeSelector.vue'
import ItemSelector from '@/components/selectors/ItemSelector.vue'
import UserSelector from '@/components/selectors/UserSelector.vue'
import SelectBy from '@/components/table/SelectBy.vue'
import { router } from '@/router'
import { storeLienesch } from '@/store/lienesch'
import ErrorFeedback from '@/views/core/ErrorFeedback.vue'

// For some reason vue-typeahead-bootstrap can't be loaded when included in Innicore's createApp
Vue.component('VueTypeaheadBootstrap', VueTypeaheadBootstrap)

Vue.component('AppSelector', AppSelector)
Vue.component('CollectionItemSelector', CollectionItemSelector)
Vue.component('DataTypeSelector', DataTypeSelector)
Vue.component('ErrorFeedback', ErrorFeedback)
Vue.component('InfoTool', InfoTool)
Vue.component('ItemSelector', ItemSelector)
Vue.component('MonthlyForecast', MonthlyForecast)
Vue.component('SelectBy', SelectBy)
Vue.component('ShipmentSelector', ShipmentSelector)
Vue.component('ShipmentStatusSelector', ShipmentStatusSelector)
Vue.component('TrustedSupplierSelector', TrustedSupplierSelector)
Vue.component('SupplierSelector', SupplierSelector)
Vue.component('SupportEmailAddress', SupportEmailAddress)
Vue.component('UserSelector', UserSelector)

store.registerModule('lienesch', storeLienesch)

export const app = createApp(
  router,
  ['api.webapp.lienesch.co.uk', 'staging.api.webapp.lienesch.co.uk'],
  'lienesch.co.uk'
)
