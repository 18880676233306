<template>
  <div>
    <div :id="popover_target_id">{{ title }} <b-icon icon="info-circle" variant="info"></b-icon></div>

    <b-popover :target="popover_target_id" :title="popover_title" triggers="hover" placement="auto">
      <template>
        <b-link @click="show_details = !show_details">
          {{ show_details ? 'Hide' : 'Show' }} detailed explanation
        </b-link>

        <slot v-if="show_details" name="detailed_explanation"></slot>

        <slot name="popover"></slot>
      </template>
    </b-popover>
  </div>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

export default {
  name: 'InfoTool',
  mixins: [api_mixin, utils],
  props: {
    popover_target_id: {
      type: String,
      default: 'popover-target',
    },
    title: {
      type: String,
    },
    popover_title: {
      type: String,
    },
  },
  data() {
    return {
      items: [],
      show_details: false,
    }
  },
}
</script>

<style lang="scss">
.vbt-matched-text-custom {
  color: black;
  background: #eee;
}
</style>
