<template>
  <b-modal :id="name" :title="title" centered size="lg" @ok="handleOK">
    <b-container fluid>
      <div class="p-2">Check if the total number of boxes matches the amount on the packing slip.</div>
      <b-table striped :items="summarizedItems" />
    </b-container>
  </b-modal>
</template>
<script lang="ts">
export default {
  props: {
    callback: {
      type: Function,
      default: () => {
        return
      },
    },
    name: { type: String, default: '' },
    title: { type: String, default: 'Title' },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  computed: {
    summarizedItems() {
      const summarized_items = []

      // Loop through all unique orders
      const order_set = [...new Set(this.items.map((item) => item.order_nr))]
      for (const purchase_order of order_set) {
        // Loop through all items unique items in the order
        const purchase_order_lines = this.items.filter((incoming_good) => incoming_good.order_nr == purchase_order)
        const item_code_set = [...new Set(purchase_order_lines.map((item) => item.ItemCode))]
        for (const item_index in item_code_set) {
          // Find all lines for each unique itemcode
          const found_items = purchase_order_lines.filter((incoming_good) => {
            return incoming_good.ItemCode == item_code_set[item_index]
          })
          if (found_items.length) {
            // Sum the quantity_net of those lines
            const total_quantity = found_items.reduce((a, b) => a + (b['quantity_net'] || 0), 0)
            const item = {
              ItemCode: item_code_set[item_index],
              Description: found_items[0].Description,
              OrderNumber: found_items[0].order_nr,
              Quantity: total_quantity,
              Boxes: found_items.length,
            }
            // Add the summarized item to the list
            summarized_items.push(item)
          }
        }
      }
      return summarized_items
    },
  },
  methods: {
    async handleOK() {
      this.callback()
    },
  },
}
</script>
