import { createRouter } from 'innicore/router'
import UnderConstruction from 'innicore/views/core/UnderConstruction.vue'

import Devs from '@/components/Devs.vue'
import AuthLogin from '@/views/auth/AuthLogin.vue'
import AuthSignUp from '@/views/auth/AuthSignUp.vue'
import Help from '@/views/core/Help.vue'
import Home from '@/views/core/Home.vue'
import ForecastHistory from '@/views/forecast/ForecastHistory.vue'
import Forecast from '@/views/forecast/ForecastPage.vue'
import IncomingGoodsLegacy from '@/views/incoming_goods/IncomingGoodsPageLegacy.vue'
import IncomingGoodsNew from '@/views/incoming_goods/IncomingGoodsPageNew.vue'
import QualityAssurance from '@/views/incoming_goods/QualityAssurance.vue'
import QualityCheck from '@/views/incoming_goods/QualityCheck.vue'
import ShipmentOverviewPage from '@/views/incoming_goods/ShipmentOverviewPage.vue'
import LIFFPrinter from '@/views/liff-printer/LIFFPrinter.vue'

export const router = createRouter([
  { path: '/', name: 'Home', component: Home },
  { path: '/collection/lienesch', name: 'LieneschCollection', component: UnderConstruction },
  { path: '/devs', name: 'Developers', component: Devs },
  { path: '/forecast', name: 'Forecast', component: Forecast },
  { path: '/forecast/history', name: 'ForecastHistory', component: ForecastHistory },
  { path: '/help', name: 'Help', component: Help },
  { path: '/liff/printer', name: 'LIFFPrinter', component: LIFFPrinter },
  { path: '/login', name: 'Login', component: AuthLogin },
  { path: '/settings', name: 'Settings', component: UnderConstruction },
  { path: '/signup', name: 'Signup', component: AuthSignUp },
  { path: '/signup/:token', name: 'SignupToken', component: AuthSignUp },
  { path: '/track', name: 'TrackOrder', component: UnderConstruction },

  {
    path: '/incoming-goods/quality-assurance',
    name: 'IncomingGoodsQualityAssurance',
    component: QualityAssurance,
    meta: { isEmployeeOnly: true },
  },
  {
    path: '/incoming-goods/quality-check',
    name: 'IncomingGoodsQualityCheck',
    component: QualityCheck,
    meta: { isEmployeeOnly: true },
  },
  { path: '/incoming-goods/check', name: 'IncomingGoodsNew', component: IncomingGoodsNew },
  { path: '/incoming-goods/add', name: 'IncomingGoodsLegacy', component: IncomingGoodsLegacy },
  {
    path: '/incoming-goods/shipment-overview',
    name: 'ShipmentOverview',
    component: ShipmentOverviewPage,
    meta: { isEmployeeOnly: true },
  },
])
