<template>
  <div>
    <b-container class="con">
      <b-row>
        <b-col cols="12">
          <b-list-group>
            <template v-for="(error, index) in errors">
              <b-list-group-item :key="index" variant="danger" style="border: none">
                <b-row align-h="between">
                  <b-col>
                    {{ error.message }}
                  </b-col>
                  <b-col style="text-align: right">
                    <b-button variant="info" size="sm" @click="show_details = !show_details">
                      <b-icon icon="info-circle" /> View details</b-button
                    >
                  </b-col>
                </b-row>

                <template v-if="show_details">
                  <b-row>
                    <b-col>
                      <b-card>
                        <b-card-text v-for="(d, index) in details" :key="index">
                          {{ d }} : {{ options[d] }}
                        </b-card-text>
                      </b-card>
                    </b-col>
                  </b-row>
                </template>
              </b-list-group-item>
            </template>
          </b-list-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

export default {
  name: 'ErrorFeedback',
  mixins: [api_mixin, utils],
  props: {
    errors: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
    },
  },
  data() {
    return {
      show_details: false,
      user_feedback: '',
    }
  },
  computed: {
    details() {
      return Object.keys(this.options).filter((key) => !['emails'].includes(key))
    },
    error_details() {
      let ed = ''
      this.details.forEach((key) => {
        ed += `\t${key} : ${JSON.stringify(this.options[key])}\n`
      })
      return ed
    },
  },
}
</script>

<style lang="scss"></style>
