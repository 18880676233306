<template>
  <div id="quality-trusted-supplier">
    <b-row>
      <b-col cols="2">
        <b>Supplier</b>
      </b-col>
      <b-col cols="6">
        <supplier-selector
          v-model="supplier"
          :disabled="disabled"
          :reset-confirmation-prompt="resetConfirmationPrompt"
        />
      </b-col>
      <b-col v-if="supplier" align-self="center" cols="2">
        <b-spinner v-if="loading" small variant="primary" switch />
        <template v-else>
          <b-form-checkbox v-model="trusted" :disabled="disabled" switch @change="mutateTrustedSupplier"
            >Trusted supplier
          </b-form-checkbox>
        </template>
      </b-col>
    </b-row>
  </div>
</template>
<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'

import { FetchTrustedSupplierDocument, MutateTrustedSupplierDocument } from '@/graphql/generated'

export default {
  mixins: [api_mixin],
  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetConfirmationPrompt: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      supplier: null,
      loading: false,
      trusted: false,
    }
  },
  watch: {
    async supplier(change) {
      if (change) {
        this.loading = true
        this.trusted = await this.fetchTrustedSupplier()
        this.loading = false
      }
      this.$emit('input', { supplier: this.supplier, trusted: this.trusted })
    },
  },
  methods: {
    set_data() {
      this.$emit('input', { supplier: this.supplier, trusted: this.trusted })
    },
    createErrorToast(error) {
      this.$bvToast.toast(`Error: ${error}`, {
        title: 'Error',
        variant: 'danger',
        solid: true,
      })
    },
    async fetchTrustedSupplier() {
      if (!this.supplier) {
        return false
      }
      try {
        const response = await this.api_call(FetchTrustedSupplierDocument, { company: this.supplier.cmp_wwn })
        const trusted = response?.data?.data?.trusted_supplier?.edges[0]?.node?.trusted
        if (trusted === undefined) {
          // If trusted is undefined, the default is false
          return false
        }
        return trusted
      } catch (error) {
        this.createErrorToast(error)
        return false
      }
    },
    async mutateTrustedSupplier() {
      if (!this.supplier) {
        return
      }
      try {
        const response = await this.api_call_feedback(MutateTrustedSupplierDocument, {
          input: [{ company: this.supplier.cmp_wwn, trusted: this.trusted }],
        })
        const trusted = response?.data?.data?.MutateTrustedSupplier?.TrustedSupplierFields[0]?.trusted
        if (trusted !== undefined) {
          this.trusted = trusted
        } else {
          this.createErrorToast('Trusted supplier could not be edited.')
          this.trusted = !this.trusted
        }
      } catch (error) {
        this.createErrorToast(error)
        this.trusted = !this.trusted
      }
    },
  },
}
</script>
