import api_mixin from 'innicore/mixins/api_mixin'

import { FetchSuppliersDocument } from '@/graphql/generated'

// TODO, this is a stupid way to get the constants loaded before innicore installs them.
// Just a quickfix for now to see if it works
import { constants } from '../plugins/constants'

constants.select_by.select_values

export const storeLienesch = {
  state: {
    suppliers: [],
  },
  getters: {
    suppliers(state) {
      return state.suppliers
    },
    augmentedItems(state, getters, rootState) {
      const lieneschItems = rootState.lienesch_items
      getters.getCollectionItems?.forEach((collectionItem) => {
        const lieneschItem = lieneschItems.find((item) => item.ItemCode === collectionItem.ItemCode)
        lieneschItem.ItemCodeAccount = collectionItem.ItemCodeAccount
      })
      return lieneschItems
    },
    getAugmentedItemByAttribute: (state, getters) => (key, ItemCode) => {
      return getters.augmentedItems.find((augmentedItem) => augmentedItem[key] === ItemCode)
    },
  },
  mutations: {
    async setSuppliers(state, suppliers) {
      state.suppliers = suppliers
    },
  },
  actions: {
    async fetchSuppliers() {
      const response = await api_mixin.methods.api_call(
        FetchSuppliersDocument,
        { crdnr__isnull: false },
        { store: this }
      )
      const suppliers = response.data.data.Companies.edges.map((edge) => edge.node)
      this.commit('setSuppliers', suppliers)
    },
  },
}
