<template>
  <b-modal :id="modalName" :title="modalTitle" centered size="lg" @hidden="resetModal" @close="closeModal">
    <template #modal-header-close>
      <button class="close" aria-label="Close" @click="hideModal">
        <span aria-hidden="true">&times;</span>
      </button>
    </template>
    <b-container>
      <b-row>
        <b-col>
          <b-form-group label="Item code" label-cols="2">
            <b-form-input id="itemcode_readonly" v-model="itemItemCode" readonly />
          </b-form-group>
          <b-form-group :label="descriptionLabel" label-cols="2">
            <b-form-input id="description_readonly" v-model="itemDescription" readonly />
          </b-form-group>
        </b-col>
      </b-row>
      <b-form>
        <b-form-group label="Amount cut (m)" label-cols="2">
          <typed-form-input
            id="cutAmount_writeable"
            v-model="cutAmount"
            :type="Number"
            placeholder="Enter cut amount in meters..."
          />
        </b-form-group>
        <b-form-group label="Rest amount (m)" label-cols="2">
          <typed-form-input
            id="restAmount_writeable"
            v-model="restAmount"
            :type="Number"
            placeholder="Enter rest amount in meters..."
          />
        </b-form-group>

        <b-form-group label="Batch Code" label-cols="2">
          <typed-form-input
            id="batchCode_writable"
            v-model="batchCode"
            :type="String"
            placeholder="Enter batch code..."
          />
        </b-form-group>
      </b-form>
      <b-row v-if="modalErrors?.length">
        <b-col>
          <b-alert v-for="error in modalErrors" :key="error.pos" show variant="danger">
            {{ error }}
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer="{ cancel, ok }">
      <b-button v-if="switchable" id="switch_item" class="float-left" variant="success" @click="switchItem">
        {{ shadowIsLiff ? 'Switch to default item' : 'Switch to LIFF item' }}
      </b-button>
      <b-button variant="secondary" @click="cancel()">cancel</b-button>
      <hit-button variant="primary" :click="() => handleOk(ok)"> OK </hit-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
export default {
  props: {
    modalName: {
      type: String,
      required: true,
    },
    isLiff: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
    printCallback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      cutAmount: null,
      restAmount: null,
      modalErrors: [],
      shadowIsLiff: false,
      batchCode: '',
    }
  },
  computed: {
    switchable() {
      return this.item?.LIFFAlias
    },
    modalTitle() {
      return this.shadowIsLiff ? 'Print LIFF label' : 'Print label'
    },
    descriptionLabel() {
      return this.shadowIsLiff ? 'LIFF Item Description' : 'Item Description'
    },
    itemDescription() {
      return this.shadowIsLiff ? this.item?.LIFFAlias : this.item?.Description
    },
    itemItemCode() {
      return this.item?.ItemCode
    },
  },
  watch: {
    isLiff: function (newVal) {
      this.shadowIsLiff = newVal
    },
  },
  methods: {
    closeModal(modalEvent) {
      modalEvent.preventDefault()
    },
    switchItem() {
      this.shadowIsLiff = !this.shadowIsLiff
    },
    resetModal() {
      this.cutAmount = null
      this.restAmount = null
      this.batchCode = null
      this.modalErrors = []
      this.shadowIsLiff = this.isLIFF
    },
    hideModal() {
      this.$bvModal.hide(this.modalName)
    },
    validateModal() {
      this.modalErrors = []
      if (this.cutAmount === null) {
        this.modalErrors.push('No cut amount specified or is not a valid number.')
        return false
      }
      if (this.cutAmount <= 0) {
        this.modalErrors.push('Cut amount must be greater than 0.')
      }
      if (this.restAmount === null) {
        this.modalErrors.push('No rest amount specified or is not a valid number.')
        return false
      }
      if (this.restAmount <= 0) {
        this.modalErrors.push('Rest amount must be greater than 0.')
      }
      return this.modalErrors.length === 0
    },
    async handleOk(modalAction) {
      if (this.validateModal()) {
        this.printCallback(this.item, this.cutAmount, this.restAmount, this.shadowIsLiff, this.batchCode)
        modalAction()
      }
    },
  },
}
</script>
