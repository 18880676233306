<template>
  <div>
    <h1>LIFF Print page</h1>
    <p>On this page, you can print LIFF labels.</p>

    <PrintLabelModal :modal-name="modalName" :is-liff="isLIFF" :item="item" :print-callback="printLabel" />

    <i-table
      app-name="liff-printer"
      :fetch-items="fetchItems"
      :fields="fields"
      :actions="actions"
      :allowed-selectors="[['customer']]"
      @update:selectBy="(v) => (select_by = v)"
    />
  </div>
</template>

<script lang="ts">
import axios from 'axios'

import useTableDefaultFields, { DefaultFieldGroups } from 'innicore/components/table/useTableDefaultFields'
import api_mixin from 'innicore/mixins/api_mixin'

import { FetchLIFFCustomerDocument, FetchLIFFItemsDocument } from '@/graphql/generated'
import { LIFFItemLabel } from '@/views/liff-printer/LIFFLabel'
import PrintLabelModal from '@/views/liff-printer/PrintLabelModal.vue'

export default {
  name: 'LIFFPrinter',
  components: { PrintLabelModal },
  mixins: [api_mixin],
  data() {
    return {
      modalName: 'liff_printer_modal',
      printerAddress: import.meta.env.VITE_LIFF_LABEL_PRINT_URL,
      defaultFields: [],
      actions: [
        {
          key: 'print_label',
          title: 'Print label',
          icon: 'printer',
          variant: 'primary',
          execute: (item) => this.showModal(item),
        },
      ],
      select_by: null,
      isLIFF: false,
      item: null,
    }
  },
  computed: {
    fields() {
      const fields = useTableDefaultFields(
        [DefaultFieldGroups.ItemAttributes, DefaultFieldGroups.SystemInfo],
        this.defaultFields
      )
      if (this.isLIFF) {
        // Add LIFF Alias column after item description in table
        fields.splice(3, 0, {
          key: 'LIFFAlias',
          label: 'LIFF Alias',
          sortable: true,
          formatter: (value, key, item) => {
            // TODO not sure if this is what we want.
            return item.LIFFAlias || ''
          },
        })
      }
      return fields
    },
    customer() {
      return this.select_by ? this.select_by.customer : null
    },
    items() {
      return this.$store.state.lienesch_items
    },
  },
  methods: {
    parseLIFFItem(item) {
      return {
        ItemCode: item.ItemCode,
        LIFFAlias: item.liff_alias,
      }
    },
    combineItem(item, LIFFItem) {
      return {
        ...item,
        LIFFAlias: LIFFItem.LIFFAlias,
      }
    },
    combineItemsAndLIFFItems(LIFFItems) {
      return this.items.map((item) => {
        const liffItem = LIFFItems.find((liffItem) => liffItem.ItemCode === item.ItemCode)
        if (liffItem) {
          return this.combineItem(item, liffItem)
        }
        return item
      })
    },
    async fetchItems() {
      const account = this.customer?.cmp_wwn
      if (!account) {
        return this.items
      }
      try {
        const LIFFCustomerResponse = await this.api_call(FetchLIFFCustomerDocument, { account: account })
        this.isLIFF = LIFFCustomerResponse.data?.data?.is_liff_customer
        if (this.isLIFF === null) {
          this.isLIFF = false
          throw new Error('Retrieving LIFF customer failed.')
        }
        this.isLIFF = true
        const LIFFItemsResponse = await this.api_call(FetchLIFFItemsDocument)
        const LIFFItems = LIFFItemsResponse.data.data.Item.edges.map((item) => this.parseLIFFItem(item.node))
        return this.combineItemsAndLIFFItems(LIFFItems)
      } catch (error) {
        this.addError(`An error occurred while fetching items: ${error.message}`)
      }
    },
    createLabels(item, cutAmount, restAmount, batchCode) {
      return new LIFFItemLabel(
        item.ItemCode,
        batchCode,
        cutAmount,
        restAmount,
        item.width,
        item.color_code,
        item.ean_code,
        item.LIFFAlias
      )
    },
    buildCSV(item) {
      return [LIFFItemLabel.CSVHeader].concat(item).join('\n')
    },
    async printLabel(item, cutAmount, restAmount, isLIFF, batchCode) {
      const label = this.createLabels(item, cutAmount, restAmount, batchCode)
      const csv = this.buildCSV(label)

      try {
        // TODO isLIFF is not yet used
        await axios.post(
          this.printerAddress,
          { csv: csv, isLIFF: isLIFF },
          { headers: { 'Content-Type': 'text/plain' } }
        )
        this.$bvModal.hide(this.modalName)
        this.$bvToast.toast('Label printed successfully.', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      } catch (error) {
        if (error.message === 'Network Error') {
          this.addError('Could not connect to printer. Please check if the printer is online.')
        } else {
          this.addError(`An unknown error occurred with message: ${error.message}.`)
        }
      }
    },
    addError(error) {
      this.$bvToast.toast(error, {
        title: 'Error',
        variant: 'danger',
        solid: true,
      })
    },
    showModal(item) {
      this.item = item
      this.$bvModal.show(this.modalName)
    },
  },
}
</script>
