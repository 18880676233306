import * as _ from 'lodash'

import type { Field } from 'innicore/components/table/TableFieldsMixin'
import useTableDefaultFields, {
  type DefaultField,
  DefaultFieldGroups,
  mergeFields,
} from 'innicore/components/table/useTableDefaultFields'

//Re-export the default field groups for ease of access
export { DefaultFieldGroups } from 'innicore/components/table/useTableDefaultFields'

//Customer-specific default fields. They will be merged with the default fields fromk @/components/table/useTableDefaultFieldsCustomer.ts
const CUSTOMER_DEFAULT_FIELDS: Array<DefaultField> = []
/**
 * Add customer-specific table fields to the supplied field array. The 'fields' option can be used to overwrite/add fields.
 */
export default (defaultFieldGroups: Array<DefaultFieldGroups> = [], fields: Array<Field> = []): Array<Field> => {
  const customerFields: Array<Field> = _.cloneDeep(
    CUSTOMER_DEFAULT_FIELDS.filter((x) => defaultFieldGroups.includes(x.defaultGroup))
  )
  const defaultFields = useTableDefaultFields(defaultFieldGroups, customerFields)
  return mergeFields(defaultFields, fields)
}
