<template>
  <div class="quality-check">
    <h1>Quality Check</h1>
    <p>You can approve/disapprove incoming goods here.</p>

    <b-modal
      id="approveModal"
      :title="modalApproveValue"
      ok-variant="success"
      cancel-variant="danger"
      @ok="ok"
      @cancel="cancel"
      @hidden="cancel"
    >
      <p>Are you sure you want to {{ modalApproveValue.toLowerCase() }} this item?</p>
      <b-textarea
        v-model="comment"
        placeholder="Enter an optional comment here..."
        class="mt-4"
        rows="3"
        max-rows="6"
      />
    </b-modal>

    <i-table
      ref="ITable"
      :fetch-items="fetchItems"
      :fields="fields"
      app-name="quality-check"
      :allowed-selectors="allowed_selectors"
      @update:selectBy="(v) => (select_by = v)"
    >
      <template #cell(quality_approved)="row">
        <b-form-checkbox
          v-model="row.item.quality_approved"
          :approved="row.item.quality_approved"
          switch
          @change="showModal(row.item)"
        />
      </template>
    </i-table>
  </div>
</template>

<script lang="ts">
import alerts, { VARIANT } from 'innicore/mixins/alerts'
import api_mixin from 'innicore/mixins/api_mixin'

import { MutateQualityCheckDocument, fetchQualityCheckItemsDocument } from '@/graphql/generated'

export default {
  name: 'QualityCheck',
  mixins: [api_mixin, alerts],
  data() {
    return {
      fields: [
        { key: 'ItemCode', label: 'Item Code', selected: true, sortable: true, editable: false },
        { key: 'Description', label: 'Description', selected: true, sortable: true, editable: false },
        { key: 'batch_code', label: 'Batch code', selected: true, sortable: true, editable: false },
        { key: 'quality_approved', label: 'Approved', selected: true, sortable: true, editable: true },
      ],
      select_by: null,
      modalIncomingGood: null,
    }
  },
  computed: {
    allowed_selectors(): string[][] {
      return [['supplier']]
    },
    // modal values
    modalApproveValue(): string {
      return this.modalIncomingGood?.quality_approved ? 'Approve' : 'Disapprove'
    },
    comment: {
      get(): string {
        return this.modalIncomingGood?.quality_comment
      },
      set(value: string): void {
        this.modalIncomingGood.quality_comment = value
      },
    },
  },
  methods: {
    parseIncomingGood(incomingGood): object {
      return {
        id: incomingGood.id,
        ItemCode: incomingGood.item.ItemCode,
        Description: incomingGood.item.Description,
        batch_code: incomingGood.batch_code,
        quality_approved: incomingGood.quality_approved,
        quality_comment: incomingGood.quality_comment,
      }
    },
    async mutateQualityCheck(qualityCheck: object): Promise<boolean> {
      try {
        const response = await this.api_call_feedback(MutateQualityCheckDocument, {
          input: [
            {
              id: qualityCheck.id,
              quality_approved: qualityCheck.quality_approved,
              quality_comment: qualityCheck.quality_comment,
            },
          ],
        })
        if (response.data.errors) {
          this.showToast(response.data.errors[0].message, VARIANT.DANGER)
          return false
        }
        return true
      } catch (error) {
        console.error(error.message)
        this.showToast(error.message, VARIANT.DANGER)
        return false
      }
    },
    fetchItems(filters: object) {
      if (!filters?.supplier) {
        return []
      }
      return this.api_call(fetchQualityCheckItemsDocument, {
        company: filters.supplier.cmp_wwn,
      }).then((response) => {
        if (response.data.errors) {
          this.showToast(response.data.errors[0].message, VARIANT.DANGER)
        } else {
          return response.data.data.incoming_goods.edges.map((incomingGood) => {
            return this.parseIncomingGood(incomingGood.node)
          })
        }
      })
    },
    // modal methods
    showModal(incomingGood: object): void {
      this.modalIncomingGood = incomingGood
      this.$bvModal.show('approveModal')
    },
    async ok(action: Event): Promise<void> {
      action.preventDefault()
      const result = await this.mutateQualityCheck(this.modalIncomingGood)
      if (!result) {
        this.modalIncomingGood.quality_approved = !this.modalIncomingGood.quality_approved
      }
      this.$bvModal.hide('approveModal')
      this.modalIncomingGood = null
    },
    cancel(): void {
      if (this.modalIncomingGood) {
        // It's called twice, once on cancel, once on hide
        this.modalIncomingGood.quality_approved = !this.modalIncomingGood.quality_approved
        this.modalIncomingGood = null
      }
      this.$bvModal.hide('approveModal')
    },
  },
}
</script>
