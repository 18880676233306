import { parseItem as defaultParseItem } from 'innicore/common/parseItem'

import { ItemFragment } from '@/graphql/generated'

export function parseItem(node: ItemFragment) {
  const item = defaultParseItem(node)
  return {
    ...item,

    color_code: node.color_code,
    ean_code: node.ean_code,
  }
}
