<template>
  <b-container id="shipment-selector" class="con">
    <b-row v-if="supplier">
      <b-col cols="2">
        <b>Select shipment</b>
      </b-col>

      <b-col cols="4">
        <generic-selector
          v-model="shipment"
          :data="shipment_options"
          :disabled="loading || disabled"
          :serializer="serialize_shipment"
          :variant-function="(data) => (data.status == 'SENT' ? 'success' : '')"
          invalid-feedback-message="This shipment does not exist!"
          placeholder="Select or create shipment"
        />
      </b-col>
      <b-col v-if="shipment != null && shipment?.id == undefined" cols="4">
        <b-form-input
          v-model="reference"
          type="text"
          placeholder="Enter your reference"
          :disabled="disabled"
          debounce="500"
        />
      </b-col>
    </b-row>
  </b-container>
</template>
<script lang="ts">
import api_mixin from 'innicore/mixins/api_mixin'
import utils from 'innicore/mixins/utils'

import { FetchIncomingGoodsShipmentsDocument, IncomingGoodStatus } from '@/graphql/generated'

export default {
  name: 'IncomingGoodsShipmentComponent',
  mixins: [api_mixin, utils],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => null,
    },
    supplier: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['input'],
  data() {
    return { shipment: null, shipments: [], loading: false, reference: '' }
  },
  computed: {
    shipmentIds() {
      return this.shipments.map((x) => x.id)
    },
    shipment_options() {
      return [{}, ...this.shipments]
    },
  },
  watch: {
    shipment() {
      if (!this.shipment) {
        this.reference = ''
      }
      this.$emit('input', this.shipment)
    },
    reference() {
      this.$emit('input', { ...this.shipment, reference: this.reference })
    },
    value(new_value) {
      if ((new_value.id !== undefined) & !this.shipmentIds.includes(new_value.id)) {
        this.shipments.splice(0, 0, this.value)
        this.shipment = this.value
      } else if (this.shipmentIds.includes(new_value.id)) {
        this.shipments.find((shipment) => shipment.id == new_value.id).status = new_value.status
      }
    },
    async supplier(new_supplier) {
      if (new_supplier) {
        this.loading = true
        this.shipments = await this.fetchShipments(this.supplier.cmp_wwn)
        // Sort on assending arrival_timestamp
        this.shipments.sort((a, b) => b.arrival_timestamp - a.arrival_timestamp)
        this.loading = false
      }
    },
  },

  methods: {
    parseShipment(node) {
      return {
        id: node.id,
        arrival_timestamp: new Date(node.arrival_timestamp),
        reference: node.reference,
        unlocked: node.unlocked,
        status: node.status,
      }
    },
    fetchShipments(company: string) {
      if (company) {
        return this.api_call(FetchIncomingGoodsShipmentsDocument, {
          company: company,
          unlocked: false,
          // Only fetch Draft or Rejected shipments for the shipment selector
          status_list: [IncomingGoodStatus.DRAFT, IncomingGoodStatus.REJECTED],
        }).then((incoming_goods_response) => {
          return incoming_goods_response.data.data.incoming_goods_shipment.edges.map((obj) =>
            this.parseShipment(obj.node)
          )
        })
      } else {
        return null
      }
    },
    serialize_shipment(shipment: { id: string; arrival_timestamp: Date; reference: string }) {
      if (shipment.id == undefined) {
        return 'Create new shipment...'
      }
      return shipment.reference ? shipment.reference : this.format_date_time(shipment.arrival_timestamp)
    },
  },
}
</script>
