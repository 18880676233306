export class LIFFItemLabel {
  ItemCode: string
  batchCode: string
  cutAmount: number
  restAmount: number
  width: number
  color: number
  ean_code: string
  liffAlias: string

  constructor(
    ItemCode: string,
    batchCode: string,
    cutAmount: number,
    restAmount: number,
    width: number,
    color: number,
    ean_code: string,
    liffAlias: string
  ) {
    this.ItemCode = ItemCode
    this.batchCode = batchCode
    this.cutAmount = cutAmount
    this.restAmount = restAmount
    this.width = width
    this.color = color
    // if the ean_code is of length 13 it needs to be prepended with a 0
    this.ean_code = ean_code.length == 13 ? '0' + ean_code : ean_code
    this.liffAlias = liffAlias
  }

  get CSVRow() {
    return [
      this.ItemCode,
      this.batchCode,
      this.width,
      this.cutAmount,
      this.cutAmount,
      this.restAmount,
      this.restAmount,
      this.color,
      ((this.width * this.cutAmount) / 100).toFixed(2),
      ((this.width * this.restAmount) / 100).toFixed(2),
      this.ean_code,
      2400,
      this.liffAlias,
    ].join(';')
  }

  static get CSVHeader() {
    return [
      'Artikelcode',
      'Batchnummer',
      'Breedte',
      'Netto',
      'Bruto',
      'RestNetto',
      'RestBruto',
      'Kleur',
      'VierkanteMeter',
      'RestVierkanteMeter',
      'EANCode',
      'BreedteBarcode',
      'Liff',
    ].join(';')
  }

  toString() {
    return this.CSVRow
  }
}
