<template>
  <div>
    <h1>Incoming goods shipment overview</h1>
    <p>On this page you can add, release and check new incoming goods shipments.</p>
    <i-table
      ref="ITable"
      :fetch-items="fetchShipments"
      :fields="fields"
      app-name="incoming_goods_release"
      :allowed-selectors="allowed_selectors"
      :errors="errors"
      :actions="actions"
      default-sort-by="arrival_timestamp"
      :default-sort-desc="true"
      @update:selectBy="(v) => (select_by = v)"
      @update:tableMode="(tm) => (tableMode = tm)"
    >
      <template #cell(reference)="row">
        <router-link :to="{ name: referencePage, query: { shipment: row.item.id } }">{{
          row.item.reference
        }}</router-link>
      </template>
    </i-table>
  </div>
</template>
<script lang="ts">
import { TABLE_MODE } from 'innicore/components/table/TableModeMixin'
import useTableDefaultFields, { DefaultFieldGroups } from 'innicore/components/table/useTableDefaultFields'
import api_mixin from 'innicore/mixins/api_mixin.js'
import utils from 'innicore/mixins/utils.js'

import { FetchIncomingGoodsShipmentsDocument, IncomingGoodStatus } from '@/graphql/generated'
import AddShipmentModal from '@/views/incoming_goods/AddShipmentModal.vue'

export default {
  name: 'ShipmentOverview',
  mixins: [api_mixin, utils],
  data() {
    return {
      errors: [],
      select_by: null,
      fields: [],
      test_flag: this.$store.state.user.is_tester,
      referencePage: 'IncomingGoodsLegacy',
      tableMode: null,
      extraFields: [
        {
          key: 'cmp_name',
          label: 'Supplier',
          selected: true,
        },
        {
          key: 'reference',
          label: 'Reference',
          sortable: true,
          optional: false,
          type: Text,
        },
        {
          key: 'arrival_timestamp',
          label: 'Arrival date',
          sortable: true,
          optional: true,
          selected: true,
          formatter: this.serializeDate,
          type: Date,
          insertBefore: 'unit',
        },
      ],
    }
  },
  computed: {
    actions() {
      if (!this.test_flag) return []
      return [
        { key: 'edit', disallow: true },
        { key: 'delete', disallow: true },
      ]
    },
    supplier() {
      return this.select_by?.supplier
    },
    allowed_selectors() {
      return [['shipment_status'], ['supplier']]
    },
  },
  mounted() {
    this.fields = useTableDefaultFields(
      [DefaultFieldGroups.CustomerAttributes, DefaultFieldGroups.SystemInfo],
      this.extraFields
    )
    if (this.test_flag) this.referencePage = 'IncomingGoodsNew'
  },
  methods: {
    parseShipmentNode(node) {
      // if the shipment is unlocked
      node._rowVariant = 'success'
      return {
        ...node,
        ...node.company,
      }
    },
    async fetchShipments(filters) {
      if (!filters?.supplier && this.test_flag) return null
      // We fetch all statuses by default
      let statuses = [
        IncomingGoodStatus.DRAFT,
        IncomingGoodStatus.SENT,
        IncomingGoodStatus.PROCESSED,
        IncomingGoodStatus.ACCEPTED,
        IncomingGoodStatus.REJECTED,
      ]
      // If the flag is set we try to take the the shipment statuses from the shipment status selector
      if (this.test_flag && filters?.shipment_status !== undefined) {
        // filter out the released status as this is not a status in the database but only in the selector
        statuses = filters.shipment_status.filter((status) => status !== 'RELEASED')
      }
      return {
        query: FetchIncomingGoodsShipmentsDocument,
        parameters: {
          company: this.supplier?.cmp_wwn,
          status_list: statuses,
        },
        after: (response) => {
          return response.data.data.incoming_goods_shipment.edges.map((obj) => this.parseShipmentNode(obj.node))
        },
      }
    },
    serializeDate(date) {
      if (date === null) return 'Not set'
      return this.format_date(new Date(date))
    },
  },
}
</script>
