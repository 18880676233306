<template>
  <div id="shipment-status-selector">
    <fancy-input
      v-model="status"
      :options="statuses"
      :disabled="disabled"
      :serializer="(status) => serialize_status(status)"
    />
  </div>
</template>
<script lang="ts">
import utils from 'innicore/mixins/utils.js'

import { IncomingGoodStatus } from '@/graphql/generated'

export default {
  mixins: [utils],
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    resetConfirmationPrompt: {
      type: String,
      default: 'Reset',
    },
  },
  data() {
    return {
      statuses: [
        IncomingGoodStatus.DRAFT,
        IncomingGoodStatus.SENT,
        IncomingGoodStatus.PROCESSED,
        IncomingGoodStatus.ACCEPTED,
        IncomingGoodStatus.REJECTED,
        // This value is not in the enumeration since this is not a status in Exact
        'RELEASED',
      ],
      serializations: {
        DRAFT: 'Draft',
        SENT: 'Sent to exact',
        PROCESSED: 'Processed',
        ACCEPTED: 'Ready for release',
        REJECTED: 'Rejected',
        RELEASED: 'Released',
      },
    }
  },
  computed: {
    status: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  mounted() {
    this.status = [
      IncomingGoodStatus.DRAFT,
      IncomingGoodStatus.SENT,
      IncomingGoodStatus.PROCESSED,
      IncomingGoodStatus.ACCEPTED,
      IncomingGoodStatus.REJECTED,
    ]
  },
  methods: {
    serialize_status(status: string) {
      return this.serializations[status]
    },
  },
}
</script>
