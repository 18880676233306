<template>
  <div>
    <div class="mb-5 col-6">
      <h1>Welcome to the Lienesch Ltd. webapp!</h1>
      <p>
        Please note that this is a beta version, if you encounter any bugs, glitches, lack of functionality or other
        problems on the website, please let us know immediately so we can rectify these accordingly. Your help in this
        regard is greatly appreciated! You can contact us at <support-email-address></support-email-address>.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  data() {
    return {
      email: '',
      password: '',
      query: '',
      error: null,
      support_address: import.meta.env.VITE_SUPPORT_ADDRESS,
      mailto: `mailto:${import.meta.env.VITE_SUPPORT_ADDRESS}`,
    }
  },
  computed: {
    key() {
      return this.$store.state.token
    },
  },
}
</script>
